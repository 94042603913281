<template>
  <div class="container">
    <div class="tools">
      <div class="tools-search">
        <div class="tools-top">
          <div class="tools-search__item">
            <label style="width: 52px">用户：</label>
            <el-input
              v-model.trim="searchForm.userIdOrName"
              size="mini"
              :maxlength="25"
              placeholder="请输入用户ID/昵称"
            ></el-input>
          </div>
          <div class="tools-search__item">
            <label>电话号码：</label>
            <el-input
              v-model.trim="searchForm.userPhone"
              size="mini"
              :maxlength="11"
              placeholder="请输入电话号码"
            ></el-input>
          </div>
          <div class="tools-search__item">
            <label>所属企业：</label>
            <el-input
              v-model.trim="searchForm.belongObjectName"
              size="mini"
              :maxlength="25"
              placeholder="请输入企业名称"
            ></el-input>
          </div>
          <div class="tools-button">
            <el-button type="text" @click="collapse = !collapse">{{
              collapse ? "收起" : "展开"
            }}</el-button>
            <el-button
              type="primary"
              size="mini"
              :loading="searchLoding"
              @click="onSearch"
              >查询</el-button
            >
            <el-button
              size="mini"
              icon="el-icon-refresh-left
"
              @click="onReset"
              >重置</el-button
            >
          </div>
        </div>
        <el-collapse-transition>
          <div v-show="collapse" class="tools-bottom">
            <div class="tools-search__item">
              <label>盲盒类型：</label>
              <el-select
                v-model="searchForm.boxType"
                size="mini"
                placeholder="请选择盲盒类型"
              >
                <el-option label="企业" :value="0"></el-option>
                <el-option label="个人" :value="1"></el-option>
              </el-select>
            </div>
            <div class="tools-search__item">
              <label>奖品状态：</label>
              <el-select
                v-model="searchForm.prizeStatus"
                size="mini"
                placeholder="请选择奖品状态"
              >
                <el-option label="待兑换" :value="0"></el-option>
                <el-option label="已兑换" :value="1"></el-option>
                <el-option label="已失效" :value="2"></el-option>
              </el-select>
            </div>
            <div class="tools-search__item">
              <label>所属盲盒：</label>
              <el-input
                v-model.trim="searchForm.belongBox"
                size="mini"
                :maxlength="25"
                placeholder="请输入盲盒名称"
              ></el-input>
            </div>
          </div>
        </el-collapse-transition>
      </div>
      <div class="tools__add">
        <el-button size="mini" icon="el-icon-delete" @click="onDelAll"
          >删除</el-button
        >
        <el-button size="mini" icon="el-icon-upload2" @click="onExport"
          >导出数据</el-button
        >
      </div>
    </div>
    <el-table
      ref="multipleTable"
      :data="dataList"
      border
      :max-height="803"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="序号" width="50">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="userId"
        label="用户ID"
        width="70"
      ></el-table-column>
      <!-- <el-table-column prop="relationUser" label="关联用户"></el-table-column> -->
      <el-table-column prop="userName" label="用户昵称" min-width="100px">
      </el-table-column>
      <el-table-column prop="userPhone" label="电话号码" min-width="100px">
      </el-table-column>
      <el-table-column prop="boxType" label="盲盒类型">
        <template slot-scope="scope">
          {{ scope.row.boxType === 0 ? "企业" : "个人" }}
        </template>
      </el-table-column>
      <el-table-column prop="belongObject" label="所属企业">
        <template slot-scope="scope">
          {{ scope.row.boxType === 0 ? scope.row.belongObject : "个人" }}
        </template>
      </el-table-column>
      <el-table-column prop="prizeNo" label="奖品ID"> </el-table-column>
      <el-table-column prop="prizeName" label="奖品名称"> </el-table-column>
      <el-table-column prop="belongBox" label="所属盲盒"> </el-table-column>
      <el-table-column prop="winningDate" label="中奖日期"> </el-table-column>
      <el-table-column
        prop="boxRedeemDeadline"
        label="兑奖截止时间"
        min-width="120px"
      >
      </el-table-column>
      <el-table-column prop="prizeStatus" label="状态">
        <template slot-scope="scope">
          <el-link
            style="cursor: text"
            :style="{
              color:
                scope.row.prizeStatus === 1
                  ? '#111'
                  : scope.row.prizeStatus === 0
                  ? '#ec9727'
                  : '#cf7b6b',
            }"
            :underline="false"
            >{{
              scope.row.prizeStatus === 1
                ? "已兑换"
                : scope.row.prizeStatus === 0
                ? "待兑换"
                : "已失效"
            }}</el-link
          >
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="90px">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.prizeStatus === 0"
            type="text"
            size="small"
            @click="handleClick(scope.row)"
            >兑换</el-button
          >
          <el-button
            v-if="scope.row.prizeStatus !== 0"
            type="text"
            size="small"
            @click="onDel(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="page.current"
      :page-size="page.size"
      background
      :page-sizes="[10, 20, 30, 40]"
      layout="prev, pager, next, sizes, jumper"
      :total="page.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import request from "@/mixins/request.js";
import {
  getBlindBoxPrizeList,
  exchangePrize,
  deletePrize,
  deletePrizeALL,
  exportList,
} from "@/api/blindBox.js";
export default {
  mixins: [request],
  data() {
    return {
      collapse: false,
      selectionBlindBox: [],
    };
  },
  mounted() {
    this.getData(getBlindBoxPrizeList);
  },
  methods: {
    /**
     * 导出中奖信息
     */
    onExport() {
      if (!this.selectionBlindBox.length)
        return this.$message.error("请选择需要导出的数据~");
      const data = this.selectionBlindBox.map((e) => e.id);
      exportList({
        data,
      }).then((res) => {
        console.log(res);
        const blob = new Blob([res]); // 处理文档流
        const fileName = "中奖信息列表.xlsx";
        const elink = document.createElement("a");
        elink.download = fileName;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      });
    },
    /**
     * 批量删除
     */
    async onDelAll() {
      console.log(this.selectionBlindBox);
      if (!this.selectionBlindBox.length)
        return this.$message.error("请选择需要删除的数据~");
      const data = [];
      for (const item of this.selectionBlindBox) {
        if (item.prizeStatus === 0) {
          return this.$message.error("不能删除未兑换的数据！");
        }
        data.push(item.id);
      }

      try {
        await this.confirm();
        await deletePrizeALL({ data });
        this.getData();
      } catch (error) {}
    },
    /**
     * 删除指定中奖记录
     */
    async onDel(row) {
      if (row.prizeStatus === 0) {
        return this.$message.error("不能删除未兑换的数据！");
      }
      try {
        await this.confirm();
        await deletePrize({ data: row.id });
        this.getData();
      } catch (error) {}
    },
    /**
     * 兑换奖品
     */
    async handleClick(row) {
      try {
        await this.confirmDel();
        await exchangePrize({ data: row.id });
        this.$message.success("兑换成功！");
        this.getData();
      } catch (error) {
        console.log("error?");
      }
    },
    /**
     * 选中事件
     */
    handleSelectionChange(e) {
      this.selectionBlindBox = e;
    },
    async confirmDel() {
      return await this.$confirm("是否确定兑换？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
    },
    async confirm() {
      return await this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 24px 20px;
  box-sizing: border-box;
}
.tools {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  justify-content: space-between;
  &-search {
    overflow: hidden;
    margin-right: 20px;
    ::v-deep .el-input {
      width: 160px;
    }
    &__item {
      display: flex;
      align-items: center;
      height: 40px;
      margin-right: 20px;
      label {
        width: 90px;
        padding-right: 10px;
        font-size: 14px;
        color: #606266;
        box-sizing: border-box;
        text-align: right;
      }
    }
  }
  &-top {
    display: flex;
  }
  &-bottom {
    display: flex;
  }
  &-button {
    width: 256px;
    height: 40px;
    display: flex;
    align-items: center;
  }
  &__add {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
::v-deep .is-disabled {
  line-height: 0;
}
</style>
